<template>
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    fill="#404040"
    viewBox="0 0 33 23"
    width="33"
    height="23"
  >
    <path
      id="Layer"
      fill-rule="evenodd"
      class="s0"
      d="m29.2 0q0.3 0 0.5 0.1c0.8 0.1 1.5 0.7 1.9 1.4q0 0 0 0 0.2 0.3 0.3 0.6 0.2 0.4 0.2 0.9 0 0.2 0 0.3v11q0 0.3 0 0.6-0.1 0.3-0.2 0.6c-0.2 0.6-0.5 1.1-1 1.4-0.5 0.4-1 0.6-1.5 0.6h-5.6q-0.2 2.3-1.2 3.4-0.5 0.6-1.2 0.8-0.7 0.3-1.4 0.1-0.2-0.1-0.4-0.3-0.1 0.1-0.2 0.2-0.5 0.7-1.3 0.9c-0.6 0.2-1.2 0.1-1.7-0.1q-0.4-0.2-0.7-0.5-0.3-0.3-0.4-0.7-0.3 0.2-0.7 0.4c-0.7 0.3-1.4 0.4-2.1 0.1q-0.3-0.1-0.6-0.4-0.3-0.4-0.4-0.8c-0.9 0.5-2.1 0.8-3.8 0.8l-6.9 0.5q-0.2 0-0.3-0.1-0.1 0-0.2-0.1-0.1-0.1-0.2-0.2 0-0.2 0-0.3 0-0.2 0-0.3 0-0.1 0.1-0.3 0.1-0.1 0.2-0.1 0.2-0.1 0.3-0.1l7-0.5c2.2 0 3.3-0.4 4.1-1.2 0.9-0.7 1.6-1.8 2.8-3l0.1-0.1h0.1c0 0 2.3-0.9 3.8-2.1 0.7-0.6 1.1-1.2 1.3-1.9q0.1-0.4 0.1-0.8-0.1-0.5-0.4-0.8c-0.2-0.5-0.6-0.5-1.3-0.4-0.6 0.2-1.5 0.6-2.4 1.1l-5.9 3.1q-0.1 0.1-0.3 0.1-0.1 0.1-0.2 0-0.2 0-0.3-0.1-0.1-0.1-0.1-0.3-0.1-0.1-0.1-0.3 0-0.1 0.1-0.3 0-0.1 0.1-0.2 0.1-0.1 0.3-0.2l0.8-0.4v-8.7q0-0.2 0.1-0.3c-0.5-0.3-0.8-0.2-1.2 0-0.4 0.3-0.9 0.9-1.5 1.5-0.7 0.6-2.3 2-3.8 3.2-1.5 1.2-2.8 2.3-2.8 2.3q-0.1 0.1-0.3 0.1-0.1 0.1-0.2 0.1-0.2-0.1-0.3-0.1-0.1-0.1-0.2-0.3-0.1-0.1-0.1-0.2 0-0.2 0-0.4 0.1-0.1 0.1-0.2 0.1-0.2 0.3-0.2l2.7-2.3c1.5-1.2 3.2-2.6 3.7-3.1 0.6-0.5 1.1-1.2 1.8-1.7q0.6-0.3 1.3-0.3 0.4 0 0.9 0.2 0.1-0.2 0.3-0.5 0 0 0 0 0.2-0.3 0.4-0.5 0.1 0 0.1 0 0.2-0.2 0.4-0.4 0.1 0 0.1 0 0.2-0.1 0.5-0.2 0.3-0.1 0.7-0.1zm-17.5 6.6h19.2v-3h-19.2zm1 13.3c0 0.4 0.1 0.6 0.3 0.6 0.3 0.2 0.7 0.2 1.1 0q0.5-0.3 0.9-0.9 0 0 0.1-0.1 0-0.1 0-0.2c0.1-0.4 0.2-1.2 0.2-1.7h-0.4c-0.8 0.8-1.4 1.6-2.2 2.3zm3.7-0.3q-0.1 0.5 0.1 1.1 0 0.1 0.1 0.3 0.1 0.1 0.3 0.2c0.4 0.2 0.6 0.2 0.9 0.1q0.4-0.2 0.8-0.6c-0.1 0.1 0.1-0.1 0.2-0.3 0.1 0 0.1 0 0.1-0.1q-0.1-0.8 0-1.6 0-0.6 0.1-1.1h-2.4c0 0.4-0.1 1.3-0.2 2zm3.7-0.8q0 0.7 0.1 1.3c0 0.3 0 0.3 0.2 0.3 0.4 0.2 0.8 0.1 1.3-0.4 0.4-0.4 0.7-1.2 0.8-2.4h-2.2c0 0.3-0.1 0.8-0.2 1.2z"
    />
  </svg>
</template>
