<template>
  <div class="flex flex-column align-items-center justify-content-center">
    <SelectButton
      v-model="selected_withdrawal"
      :options="withdrawal_wallet_options"
      dataKey="id"
      option-value="id"
      optionDisabled="disable"
      class="currency-select-buttons"
    >
      <template #option="slotProps">
        <div
          class="flex flex-column align-items-center justify-content-center"
          @click="setPayMethod(slotProps.option.id)"
          :style="{
            position: 'relative',
            padding: '100%',
          }"
        >
          <span
            style="display: flex; justify-content: center; align-items: center"
          >
            {{ slotProps.option.name }}</span
          >
          <span class="mt-1 text-sm" v-if="slotProps.option.name !== 'Cliente'">
            {{ `${slotProps.option.account_balance} BUSD` }}
          </span>
          <slot></slot>
          <span v-if="slotProps.option.name == 'Cliente'" class="">
            <i
              v-if="slotProps.option.status === 2"
              class="pi pi-exclamation-triangle"
              style="color: yellow"
            ></i>
            <i
              v-if="slotProps.option.status === 1"
              class="pi pi-check-circle"
              style="color: green"
            ></i>
            <i
              v-if="slotProps.option.status === 3"
              class="pi pi-times-circle"
              style="color: red"
            ></i>
          </span>
        </div>
      </template>
    </SelectButton>
    <div class="w-full mt-4 payment-form-container">
      <div class="flex justify-content-center">
        <div class="grid justify-content-center">
          <div class="flex flex-column cash-form-input">
            <label><b>Total a pagar</b></label>
            <div
              class="label-input w-full flex justify-content-between align-items-center"
            >
              <i class="pi pi-dollar ml-3" />
              {{ Number((this.total_debt).toFixed(8)) }}
            </div>
            <label><b>Monto a pagar en BUSD</b></label>
            <!--  -->
            <span class="p-input-icon-left">
              <i class="pi pi-dollar" />
              <InputText
                ref="amountInput"
                id="i_amount"
                v-model="amount_to_pay"
                placeholder="0,00"
                :maxlength="9"
                inputmode="numeric"
                class="label-input w-full text-right"
                autofocus
                @input="convertDollartoVes"
                @keypress="validNumericInput"
                @paste="preventNonNumericInput"
                @keyup.enter="handlePayment"
                autocomplete="off"
                :disabled="is_processing_pay || this.isVerifing == true"
                :max="Number(Math.floor(this.total_debt * 100) / 100)"
              />
            </span>
            <label><b>Monto a pagar en Bolívares</b></label>
            <span class="p-input-icon-left">
              <i style="color:#706f6f">Bs</i>
              <InputText
                ref="amountInput"
                id="i_amount"
                v-model="amount_to_pay_ves"
                placeholder="0,00"
                :maxlength="9"
                inputmode="numeric"
                class="label-input w-full text-right"
                autofocus
                @input="convertVestoDollar"
                @keypress="validNumericInput"
                @paste="preventNonNumericInput"
                @keyup.enter="handlePayment"
                autocomplete="off"
                :disabled="is_processing_pay || this.isVerifing == true"
                :max="Number(Math.floor(this.total_debt * 100) / 100)"
              />
            </span>
            <div
              v-if="
                this.isClient == false &&
                amount_to_pay > 0 &&
                convert(amount_to_pay - Number((this.total_debt).toFixed(8))).toFixed(
                  1
                ) > 1
              "
            >
              <label class="ml-1 mr-7"
                ><b
                  >Vuelto:
                  {{
                    Number(
                      amount_to_pay - Number((this.total_debt).toFixed(8))
                    ).toFixed(2)
                  }}
                  $</b
                ></label
              >
              <label
                ><b
                  >Vuelto Bolívares:
                  {{
                    convert(
                      amount_to_pay - Number((this.total_debt).toFixed(8))
                    ).toFixed(1)
                  }}
                  VES</b
                ></label
              >
              <div>
                <label><b>Cédula:</b></label>
                <span class="p-input-icon-left">
                  <InputText
                    v-model="activeClient.dni"
                    type="text"
                    class="label-input w-full text-left"
                    @keypress="validNumericInput"
                    @paste="preventNonNumericInput"
                    @keyup.enter="walletPay"
                    autocomplete="off"
                    :disabled="true"
                  />
                </span>
                <label><b>Número de tlf:</b></label>
                <span class="p-input-icon-left mb-1">
                  <InputText
                    v-model="pagoMovilNumber"
                    type="text"
                    class="label-input w-full text-left"
                    @keypress="validNumericInput"
                    @keyup.enter="walletPay"
                    @paste="preventNonNumericInput"
                    autocomplete="off"
                    :disabled="is_processing_pay"
                  />
                </span>
                <label><b>Banco:</b></label>
                <Dropdown
                  v-model="pagoMovilBank"
                  :options="banksSorted_byCode"
                  placeholder="Seleccione una opción ..."
                  filter
                  class="w-full"
                  scrollHeight="350px"
                  :filterFields="['bank_name', 'bank_code']"
                  :autoFilterFocus="true"
                  :resetFilterOnHide="true"
                  :selectOnFocus="true"
                  dataKey="bank_code"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value" class="flex align-items-center">
                      <div>
                        <b
                          >{{ slotProps.value.bank_code }} -
                          {{ slotProps.value.bank_name }}</b
                        >
                      </div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <div>
                        <span class="mr-1"
                          ><b>{{ slotProps.option.bank_code }} -</b>
                        </span>
                      </div>
                      <div>
                        <b>{{ slotProps.option.bank_name }}</b>
                      </div>
                    </div>
                  </template>
                </Dropdown>
              </div>
            </div>
            <div
              v-if="this.isClient == true && isVerifing == true"
              class="mt-3"
            >
              <label><b>Código de Verificacion enviado al usuario</b></label>
              <span class="p-input-icon-left">
                <InputText
                  v-model="verification_code"
                  type="text"
                  class="label-input w-full text-right"
                  @keypress="validNumericInput"
                  @paste="preventNonNumericInput"
                  @keyup.enter="walletClientVerificationCode"
                  autocomplete="off"
                  :disabled="is_processing_pay"
                />
              </span>
            </div>
            <label v-if="this.isClient == true && isVerifing == true"
            class="text-center sms-label"
            @click="verificationSMSCode()"
            >Enviar Código por SMS.</label>
            <div class="inline-flex justify-content-center mt-3">
              <!-- <Button
                class="keyboard-button clean"
                :disabled="
                  isNaN(Number(amount_to_pay)) ||
                  is_processing_pay ||
                  Number(amount_to_pay) <= 0 ||
                  total_debt <= 0
                "
                @click="amount_to_pay = ''"
              >
                <icon-clean /> Limpiar
              </Button> -->
              <Button
                class="keyboard-button pay"
                @click="handlePayment"
                v-if="isVerifing == false"
                :disabled="
                  isNaN(Number(amount_to_pay)) ||
                  is_processing_pay ||
                  Number(amount_to_pay) <= 0 ||
                  total_debt <= 0
                "
                :loading="is_processing_pay"
              >
                <icon-pay /> Pagar
              </Button>
              <Button
                v-if="this.isClient == true && isVerifing == true"
                class="keyboard-button pay"
                @click="walletClientVerificationCode"
                :disabled="verification_code.length < 6"
                :loading="is_processing_pay"
              >
                <icon-pay /> Verificar código
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="hidden md:flex md:col-5 md:justify-content-center align-items-center"
      >
        <!-- <numeric-keyboard
          inputID="i_amount"
          :value="String(amount_to_pay)"
          @changed="(value) => (amount_to_pay = value)"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
// import NumericKeyboard from "../NumericKeyboard.vue";
import SelectButton from "primevue/selectbutton";
import IconPay from "@/components/svg/icons/IconPay.vue";
// import IconClean from "@/components/svg/icons/IconClean.vue";
import bank_list from "@/mixins/bank_list.js";
import HTTP from "@/service/http";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  components: {
    // NumericKeyboard,
    SelectButton,
    IconPay,
    // IconClean,
  },
  props: ["sendVerificationCode", "isVerifing"],
  emits: [
    "registerWalletPay",
    "sendWalletClientPay",
    "walletClientVerificationCode",
    "sendWalletClientVerificationCode",
    "cleaningFunction",
    "verificationSMSCode"
  ],
  mixins: [bank_list],
  data() {
    return {
      withdrawal_wallet_options: [{ id: 1, name: "Cliente" }],
      locale: "es-VE",
      selected_withdrawal: 2,
      amount_to_pay: "",
      amount_to_pay_ves: "",
      selected_wallet_method: 2,
      verification_code: "",
      isClient: false,
      amountNumberTwo: 0,
      pagoMovilBank: "",
      pagoMovilNumber: "",
      turnedAmount: "",
      isFunctionBlocked: false
    };
  },
  created() {
    if (["taquilla"].includes(this.$store.getters["auth/role"]))
      this.withdrawal_wallet_options.push({
        id: 2,
        name: "Taquilla",
        account_balance: 0,
        disable: false,
        status: 0,
      });
    this.refreshWalletsButtons();
    this.amount_to_pay = Number((this.total_debt).toFixed(8));
    this.amount_to_pay_ves = parseFloat(Number(this.amount_to_pay * this.convert(1)).toFixed(8))
  },
  methods: {
    ...mapActions("ticketoffice", ["getUserBalance", "creditPay"]),
    formatNumberToLocale(number, locale = "es-VE") {
      return Number(number).toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    setInputFocus(input_id) {
      setTimeout(() => {
        let input = document.getElementById(input_id);
        if (input) input.focus();
      }, 60);
    },
    validNumericInput(event) {
      if (
        !/\d/.test(event.key) &&
        (event.key !== "." || /\./.test(event.target.value))
      )
        return event.preventDefault();
    },
    refreshWalletsButtons() {
      if (
        !this.currentPlayData ||
        !this.currentPlayData.ticket ||
        !this.currentPlayData.ticket.id
      )
        return;

      this.getUserBalance()
        .then((resp) => {
          if (
            resp.data.status === "SUCCESS" &&
            this.withdrawal_wallet_options[1]
          ) {
            console.log(resp.data.data.user_balance);
            this.withdrawal_wallet_options[1].account_balance =
              resp.data.data.user_balance;
            this.withdrawal_wallet_options[1].status =
              resp.data.data.user_balance <= 0
                ? 3
                : resp.data.data.user_balance >= this.total_debt
                ? 1
                : 2;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});

      HTTP()
        .get(`/users/get-client-balance/${this.currentPlayData.ticket.id}`)
        .then((resp) => {
          console.log("cliente", resp);
          if (resp.data.status === "SUCCESS")
            this.withdrawal_wallet_options[0].status =
              resp.data.data.balance_status;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    preventNonNumericInput(event) {
      event.preventDefault();
      const text = event.clipboardData.getData("text/plain");
      const numeric = text.replace(/[^\d]/g, "");
      document.execCommand("insertText", false, numeric);
    },
    setPayMethod(id) {
      this.selected_wallet_method = id;
      if (id === 1) {
        this.isClient = true;
      } else if (id === 2) {
        this.isClient = false;
      }
    },
    inputFocus() {
      this.setInputFocus("i_amount");
    },
    convert(amount) {
      let moneda = this.monedaOptions.find((e) => e.code === 1);
      return !isNaN(amount) && moneda && !isNaN(moneda.exchange_rate)
        ? Number(amount) * Number(moneda.exchange_rate)
        : Number(0);
    },
    calculateConvertAmount(item) {
      let convert_amount = null;
      this.payOptions.forEach((payOption) => {
        const option = payOption.options.filter(
          (option) => option.id === item.fk_payment_method
        );

        if (option.length > 0)
          convert_amount = Number(option[0].convert_amount).toFixed(2);
      });

      return convert_amount;
    },
    handlePayment() {
      if (this.selected_wallet_method === 1) {
        this.walletClientPay();
      } else if (this.selected_wallet_method === 2) {
        this.walletPay();
      }
    },
    transformPhoneNumber(pagoMovilNumber) {
      if (pagoMovilNumber.startsWith("0")) {
        this.pagoMovilNumber = "58" + pagoMovilNumber.slice(1);
      } else if (pagoMovilNumber.startsWith("580")) {
        this.pagoMovilNumber = pagoMovilNumber.slice(3);
        this.pagoMovilNumber = "58" + this.pagoMovilNumber;
      } else if (!pagoMovilNumber.startsWith("58")) {
        this.pagoMovilNumber = "58" + pagoMovilNumber;
      }
    },
    walletPay() {
      this.transformPhoneNumber(this.pagoMovilNumber);
      this.$emit(
        "registerWalletPay",
        (this.data = {
          id: this.currentPlayData.ticket.id,
          amount: Number(this.amount_to_pay).toFixed(8),
          fk_payment_method: 8,
          banco_benef: this.pagoMovilBank.bank_code,
          telf_benef: this.pagoMovilNumber,
        })
      );
      this.amount_to_pay = "";
    },
    walletClientPay() {
      if (this.amount_to_pay > this.total_debt) {
        this.amount_to_pay = Number(this.total_debt).toFixed(8);
        this.$store.dispatch("changeSystemMessage", {
          severity: "error",
          summary: `Se le va a descontar ${Number(this.amount_to_pay).toFixed(8)} BUSD al cliente`,
          life: 6000,
        });
      }
      this.$emit(
        "sendWalletClientPay",
        (this.data = {
          id: this.currentPlayData.ticket.id,
          amount:
            this.amount_to_pay < this.total_debt
              ? this.amount_to_pay
              : this.total_debt,
          fk_payment_method: 8,
        })
      );
    },
    verificationSMSCode() {
      if (this.isFunctionBlocked == false){ 
      if (this.amount_to_pay > this.total_debt) {
        this.isFunctionBlocked = true
        this.amount_to_pay = Number(this.total_debt).toFixed(8);
        this.$store.dispatch("changeSystemMessage", {
          severity: "error",
          summary: `Se le va a descontar ${Number(this.amount_to_pay).toFixed(8)} BUSD al cliente`,
          life: 6000,
        });
      }
      this.$emit(
        "verificationSMSCode",
        (this.data = {
          id: this.currentPlayData.ticket.id,
          amount:
            this.amount_to_pay < this.total_debt
              ? this.amount_to_pay
              : this.total_debt,
          fk_payment_method: 8,
        })
      );
      setTimeout(() => {
        this.isFunctionBlocked = false;
      }, 8000);
      }
    },
    walletClientVerificationCode() {
      if(this.verification_code.length >= 6){
      this.$emit(
        "sendWalletClientVerificationCode",
        (this.data = {
          id: this.currentPlayData.ticket.id,
          amount:
            this.amount_to_pay < this.total_debt
              ? this.amount_to_pay
              : this.total_debt,
          fk_payment_method: 8,
          verification_code: this.verification_code,
        })
      );
      this.amount_to_pay = "";
      this.verification_code = "";
      }
    },
    convertDollartoVes: function () {
      this.amount_to_pay_ves = parseFloat(Number(this.amount_to_pay * this.convert(1)).toFixed(8))
    },
    convertVestoDollar: function () {
      this.amount_to_pay = 
        Number(this.amount_to_pay_ves).toFixed(8) / this.convert(1)
    },
  },
  computed: {
    ...mapGetters("ticketoffice", ["activeClient", "currentPlayData"]),
    ...mapState("ticketoffice", ["is_processing_pay", "monedaOptions"]),
    total_debt() {
      return this.currentPlayData && this.currentPlayData.ticket && !isNaN(Number(this.currentPlayData.ticket.debt))
        ? Number(Number(this.currentPlayData.ticket.debt))
        : 0;
    },
    // amount_to_pay() {
    //   return Number((this.total_debt).toFixed(8))
    // },
    // amount_to_pay_ves() {
    //   return Number(this.amount_to_pay).toFixed(8) * this.convert(1)
    // },
  },
  watch: {
    selected_wallet_method(newVal) {
      if (newVal) {
        this.amount_to_pay = Number((this.total_debt).toFixed(8));
        this.verification_code = "";
        this.sendVerificationCode == false;
        this.$store.commit("ticketoffice/setIsProcessingPay", false);
        this.$emit("cleaningFunction");
        this.inputFocus();
      }
    },
    total_debt(newVal) {
      if (newVal) {
        this.amount_to_pay = parseFloat(Number((this.total_debt)).toFixed(8))
        this.amount_to_pay_ves = parseFloat(Number(this.amount_to_pay * this.convert(1)).toFixed(8))
      }
    },
  },
  mounted() {
    this.inputFocus();
  },
};
</script>
<style lang="scss" scoped>
.cash-form-input {
  max-width: 95%;
  width: 340px;
  label {
    margin-top: 15px;
  }
  .p-button {
    display: flex;
    flex-direction: column;
    background: #f1f1f1;
    width: 131px;
    min-height: 90px;
    font-size: 24px;
    border-radius: 1px;
    border: 1px solid rgba(255, 160, 1, 0.25);
    &.clean {
      color: #ffa001;
    }
    &.pay {
      color: #404040;
      &:enabled:hover {
        color: #f1f1f1 !important;
        svg {
          fill: #f1f1f1 !important;
        }
      }
    }
    &.p-disabled {
      svg {
        fill: rgba(0, 0, 0, 0.38) !important;
      }
    }
    &:enabled:hover {
      background: rgb(50, 50, 50, 100);
    }
  }
}
.status-dot {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.sms-label {
  color: #CC195A; 
  font-size: 15px; 
  font-weight: bold;
}
.sms-label:hover{
  cursor: pointer;
}
</style>
